import VueRouter, { NavigationGuardNext, Route } from 'vue-router'
import Vue from 'vue'
import { GipodRouter } from './gipod-router'
import { LogEventLevel, LogImpact } from '@/api/api.proxy'
import NotFound from '../components/not-found/not-found.vue'
import { setTitleGuard } from './navigation-guards/set-title'
import { authenticationGuard } from './navigation-guards/authenticate'
import { clearAlertsGuard } from './navigation-guards/clear-alerts'
import { appsettingsGuard } from './navigation-guards/appsettings'
import NoRoles from '../components/no-roles-module/no-roles.vue'
import { defineAsyncComponent } from "vue";

export function lazyLoadComponent(loader: any) {
  return defineAsyncComponent({
    loader,
    timeout: 3000,
  });
}

export let previousRoute: GipodRouter.GRoute = null

export function routerFactory(authGuard = true) {
  const router: GipodRouter.GRouter = new VueRouter({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes: [
      { path: '*', component: NotFound },
      {
        path: '/no-roles',
        name: 'no-role',
        component: NoRoles,
      },
    ],
  })

  router.beforeEach(setTitleGuard)
  router.beforeEach(appsettingsGuard)
  if (authGuard) {
    router.beforeEach(authenticationGuard)
  }
  router.beforeEach(clearAlertsGuard)
  router.beforeEach(
    (
      to: GipodRouter.GRoute,
      from: GipodRouter.GRoute,
      next: NavigationGuardNext,
    ) => {
      previousRoute = from
      next()
    },
  )

  return router
}

export function addNavigationLogging(to: Route, from: Route) {
  if (Vue.prototype.$log) {
    Vue.prototype.$log.send({
      message: JSON.stringify({ to: to.fullPath, from: from.fullPath }),
      logEventLevel: LogEventLevel.Information,
      logImpact: LogImpact.Low,
    })
  }
}
