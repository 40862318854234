import { Component, Vue } from 'vue-property-decorator'
import { Dashboard } from '@/dashboard-module/dashboard-routes'
import { RouteIntakes } from '@/inname-module/inname-routes'
import { RouteSamenwerkingen } from '@/samenwerkingen-module/samenwerkingen-module-routes'
import { GipodRouter } from '@/base-module/router/gipod-router'
import { GipodNavigationItemKey } from '@/plugins/routes/routes'
import { RouteHinder } from '@/hinder-module/hinder-routes'
import { DeLijnRoute } from '@/de-lijn-module/de-lijn-routes'
import { User } from '@/base-module/state/UserModule'
import { RouteKaart } from '@/kaart-module/kaart-routes'
import { RouteToelatingen } from '@/toelatingen-module/toelatingen-routes'
import { RouteSignalisatievergunningsaanvraag } from '@/toelatingen-module/signalisatievergunning-module/aanvraag-module/aanvraag-routes'
import { RouteOverzichtToelatingen } from '@/toelatingen-module/signalisatievergunning-mijn-aanvragen-overzicht/signalisatievergunning-mijn-aanvragen-overzicht-routes'
import { RouteToelatingenSignalisatie } from '@/toelatingen-module/toelatingen-signalisatie/toelatingen-signalisatie-routes'
import { RouteToelatingenGrondwerken } from '@/toelatingen-module/toelatingen-grondwerken/toelatingen-grondwerken-routes'

export interface GipodNavigationItem {
  link: GipodRouter.GRouteConfig
  label: string
  active: boolean
  visible: boolean
  childRoutes?: GipodChildNavigationItem[]
}
export interface GipodChildNavigationItem {
  link: GipodRouter.GRouteConfig
  label: string
  visible: boolean
}

@Component({
  name: 'GipodNavigation',
})
export default class GipodNavigation extends Vue {
  isOpen: boolean = false
  get dashboardRoute() {
    return Dashboard
  }

  get userIsDeLijn() {
    return User.hasScope(DeLijnRoute.meta.authorize.scopes)
  }

  get userIsContactor() {
    return User.hasScope(RouteToelatingen.meta.authorize.scopes)
  }

  get isContractorOnly() {
    return User.isContractorOnly
  }

  get toelatingenRoute() {
    if (User.isDomainAdmin) return RouteSignalisatievergunningsaanvraag
    return RouteToelatingen
  }

  get items(): GipodNavigationItem[] {
    return [
      {
        link: RouteIntakes,
        label: 'Innames',
        active:
          this.$route.meta.activeNavigation === GipodNavigationItemKey.Intake,
        visible: !this.isContractorOnly,
      },
      {
        link: RouteHinder,
        label: 'Hinder',
        active:
          this.$route.meta.activeNavigation ===
          GipodNavigationItemKey.Hindrance,
        visible: !this.isContractorOnly,
      },
      {
        link: RouteSamenwerkingen,
        label: 'Samenwerking',
        active:
          this.$route.meta.activeNavigation ===
          GipodNavigationItemKey.Cooperation,
        visible: !this.isContractorOnly,
      },
      {
        link: RouteToelatingenGrondwerken,
        label: 'Toelatingen',
        active:
          this.$route.meta.activeNavigation ===
          GipodNavigationItemKey.Toelatingen,
        visible: !this.isContractorOnly,
        childRoutes: [
          {
            link: RouteToelatingenGrondwerken,
            label: 'Grondwerken',
            visible: !this.isContractorOnly,
          },
          {
            link: RouteToelatingenSignalisatie,
            label: 'Signalisatie',

            visible: !this.isContractorOnly,
          },
        ],
      },
      {
        link: RouteToelatingen,
        label: 'Nieuwe aanvraag',
        active:
          this.$route.meta.activeNavigation ===
          GipodNavigationItemKey.Toelatingen,
        visible: this.isContractorOnly,
      },
      {
        link: RouteOverzichtToelatingen,
        label: 'Overzicht signalisatievergunningen',
        active:
          this.$route.meta.activeNavigation ===
          GipodNavigationItemKey.Signalisatievergunningen,
        visible: this.isContractorOnly,
      },
      {
        link: DeLijnRoute,
        label: 'De Lijn',
        active:
          this.$route.meta.activeNavigation === GipodNavigationItemKey.DeLijn,
        visible: this.userIsDeLijn && !this.isContractorOnly,
      },
      {
        link: RouteKaart,
        label: 'Kaart',
        active:
          this.$route.meta.activeNavigation === GipodNavigationItemKey.Kaart,
        visible: !this.isContractorOnly,
      },
    ]
  }
}
