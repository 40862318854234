export interface IEnvStyling {
  color: string
  message: string
}

export const envStyling = () => {
  const styling: IEnvStyling = {
    color: null,
    message: null,
  }

  const buildMessage =
  import.meta.env.VITE_ENV + (BUILD_VERSION ? ` ${BUILD_VERSION}` : '')

  switch (import.meta.env.VITE_ENV) {
    case 'test':
      styling.color = '#ff9800'
      styling.message = buildMessage
      break
    case 'staging':
      styling.color = '#4CAF50'
      styling.message = buildMessage
      break
    case 'beta':
      styling.color = '#2196F3'
      styling.message = buildMessage
      break
    case 'training':
      styling.color = '#b81a6d'
      styling.message = import.meta.env.VITE_ENV
      break
    case 'development':
      styling.color = '#f44336'
      styling.message = buildMessage
      break
  }

  return !!styling.message ? styling : null
}

export interface IRadioButton {
  value: string
  label: string
  id?: string
  name?: string
}

export const truncateString = (string: string, length: number) => {
  return string.length > length ? `${string.substring(0, length)}...` : string
}
