import Vue from 'vue'
import Axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  CancelTokenSource,
} from 'axios'
import { ApiProxy, LogEventLevel, LogImpact } from '@/api/api.proxy'
import { Logger } from '../logger'

import retry from 'axios-retry'
import { v4 as uuid } from 'uuid'
import { ApplicationSettings } from '../../base-module/state/ApplicationSettings'

const logger = new Logger()

function requestHandler(config: AxiosRequestConfig) {
  if (config.url.indexOf('/api') >= 0) {
    logger.send({
      message: config.url,
      logEventLevel: LogEventLevel.Information,
      logImpact: LogImpact.Low,
    })
  }
  return config
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function errorHandler(error: any, logFileName: string) {
  if (error.code) {
    if (
      !(error.request as XMLHttpRequest).responseURL.endsWith('utilities/log')
    ) {
      logger.send({
        message: error.message,
        logEventLevel: LogEventLevel.Error,
        logImpact: LogImpact.Medium,
      })
    }
  }

  if (window && error.response && error.response.status === 401) {
    if (window) {
      
      const url = 
         `${window.location.origin}/auth/login?state=${encodeURIComponent(
            window.location.pathname + window.location.search,
          )}`
       
      window.location.assign(url)
    }
  }

  return Promise.reject(error)
}

export const CancelRequest = (client: ProxyClient) => {
  client.cancel()
}

export class ProxyClient extends ApiProxy {
  private source: CancelTokenSource

  constructor(
    baseUrl: string,
    instance: AxiosInstance = Axios.create({
      transformResponse: data => {
        if (data instanceof Blob) {
          return data
        }
        try {
          const jsondata = JSON.parse(data)
          if (jsondata && jsondata.errors && jsondata.status == 400) {
            return jsondata
          }
          return data
        } catch (e) {
          return data
        }
      },
    }),
  ) {
    super(baseUrl, instance)
    this.source = Axios.CancelToken.source()
    instance.defaults.cancelToken = this.source.token
    instance.defaults.headers['x-correlation-id'] = uuid()
  }

  cancel() {
    this.source.cancel()
  }
}

export const ApiProxyFactory = () => {
  return new ProxyClient(
    import.meta.env.VITE_BASE_URI,
    configureAxios(
      Axios.create({
        transformResponse: data => {
          if (data instanceof Blob) {
            return data
          }
          try {
            const jsondata = JSON.parse(data)
            if (jsondata && jsondata.errors && jsondata.status == 400) {
              return jsondata
            }
            return data
          } catch (e) {
            return data
          }
        },
      }),
    ),
  )
}

export default {
  install(vue: typeof Vue): void {
    vue.prototype.$client = ApiProxySingleton
    vue.prototype.$clientFactory = ApiProxyFactory
  },
}

export const ApiProxySingleton = ApiProxyFactory()

export function configureAxios(
  axios: AxiosInstance,
  logFileName: string = 'proxy-client.ts',
) {
  retry(axios, {
    retries: 3,
    retryCondition: (error: AxiosError) => {
      const errorCode = parseInt(error.code, 10)
      if (errorCode === 404 || errorCode === 408 || errorCode >= 500) {
        return true
      }
      return false
    },
    shouldResetTimeout: true,
  })
  axios.interceptors.request.use(
    config => {
      return requestHandler(config)
    },
    error => {
      return errorHandler(error, logFileName)
    },
  )
  axios.interceptors.response.use(
    response => response,
    error => errorHandler(error, logFileName),
  )
  return axios
}
